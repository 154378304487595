import { useEffect, useState } from "react";

import logo from "./img/logo.png";

import { storage } from "./firebase/firebase";

import { makeStyles } from "@material-ui/core/styles";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import Loader from "react-js-loader";
import {
  Grid,
  Input,
  Button,
  Link,
  Typography,
  Toolbar,
  AppBar,
  CssBaseline,
} from "@material-ui/core";

const drawerWidth = 240;

const axios = require("axios");
const fs = require("fs");
const FormData = require("form-data");

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    // display: "flex",
  },
  logo: {
    // flexGrow: "1",
    cursor: "pointer",
    fontSize: 22,
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 17,
    fontWeight: "900",
    marginLeft: theme.spacing(2),
    textDecoration: "none !important",
    "&:hover": {
      borderBottom: "1px solid white",
    },
  },
  toolbarm: {
    justifyContent: "space-between",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  size: {
    height: 30,
    width: 110,
  },
  appBar: {
    width: `calc(100% - 0px)`,
    // marginLeft: drawerWidth,
    backgroundColor: "#1212e3",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageLinks: {
    marginLeft: "60%",
    //marginTop:100
  },
  hide: {
    display: "none",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  button: {
    backgroundColor: "#4848f9",
    color: "White",
    width: "100%",
    "&:hover": {
      backgroundColor: "#0e0ef7",
      color: "White",
    },
  },
}));

const UploadAudio = (props) => {
  //State variables
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState();
  const allInputs = { imgUrl: "" };
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [progressBar, setProgress] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = () => {};

  const handleFireBaseUpload = (e) => {
    e.preventDefault();
    console.log("start of upload");
    // async magic goes here...
    if (selectedFile === "") {
      console.error(`not an image, the image file is a ${typeof selectedFile}`);
    }
    const uploadTask = storage
      .ref(`/audio/${selectedFile.name}`)
      .put(selectedFile);
    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot);
        var progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
        console.log(progress);
        setProgress(progress);
      },
      (err) => {
        //catches the errors
        console.log(err);
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref("audio")
          .child(selectedFile.name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            setImageAsUrl((prevObject) => ({
              ...prevObject,
              imgUrl: fireBaseUrl,
            }));
          });
      }
    );
  };

  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <CssBaseline />
        <Toolbar className={classes.toolbarm}>
          <Typography variant="h4" className={classes.logo}>
            Kash4Crypto
          </Typography>
          <div className={classes.navlinks}>
            <Link href="/" className={classes.link}>
              Mint Token
            </Link>
            <Link href="/UploadArtwork" className={classes.link}>
              Upload Artwork
            </Link>
            <Link href="/UploadAudio" className={classes.link}>
              Upload Audio
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ paddingLeft: 20, textAlign: "center" }}>
          <h1 id="uploadTitle" style={{ fontWeight: "700", fontSize: 28 }}>
            Upload your audio file here in order to receive your URL for
            minting.
          </h1>
          {/* <input type="file" name="file" onChange={changeHandler} /> */}
          <input
            accept="audio/*"
            className={classes.input}
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={changeHandler}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="raised"
              component="span"
              className={classes.button}
            >
              {isSelected ? selectedFile.name : "Choose File"}
            </Button>
          </label>
          {isSelected ? (
            <div style={{ color: "#707070", fontWeight: "700" }}>
              {/* <p>Filename: {selectedFile.name}</p> */}
              <p>Filetype: {selectedFile.type}</p>
              <p>Size in bytes: {selectedFile.size}</p>
              <p>
                lastModifiedDate:{" "}
                {selectedFile.lastModifiedDate.toLocaleDateString()}
              </p>
            </div>
          ) : (
            <p style={{ color: "#707070", fontWeight: "700" }}>
              Select a file to show details
            </p>
          )}
          <div>
            <button onClick={handleFireBaseUpload}>Submit</button>
          </div>
          <br />
          {isSelected ? (
            <div>
              <p style={{ fontWeight: "900" }}>Upload Status:</p>
              <Progress
                percent={progressBar}
                status={progressBar == "100%" ? "Success" : ""}
              />
            </div>
          ) : (
            <div></div>
          )}
          {imageAsUrl.imgUrl == "" && progressBar == 100 ? (
            <div>
              <p style={{ fontWeight: "900" }}>URL Of Audio:</p>
              <Loader
                type="spinner-default"
                bgColor={"#000"}
                color={"#000"}
                size={50}
              />
            </div>
          ) : (
            <p style={{ fontWeight: "900" }}>
              URL Of Audio:
              <br></br>
              {imageAsUrl.imgUrl}
            </p>
          )}
        </Grid>
        <Grid item xs={6}>
          <h1 id="uploadTitle" style={{ textAlign: "center" }}>
            Steps to Upload your audio file
          </h1>
          <div>
            <h3> Step One : Select Your File </h3>
            <h3>
              {" "}
              Step Two : After You Have Selected Your File , Press The Submit
              Button.{" "}
            </h3>
            <h3>
              {" "}
              Step Three : It will take a few seconds for the URL to appear
              below the 'Submit' button.{" "}
            </h3>
            <h3>
              {" "}
              Step Four : Make A Note Of This URL Because You Will Need It When
              Minting.{" "}
            </h3>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadAudio;

import firebase from "firebase/app";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyD7iuoVO0WFYBl0dSPmteNVD1PA2CTlcow",
  authDomain: "nftmintertesting.firebaseapp.com",
  projectId: "nftmintertesting",
  storageBucket: "nftmintertesting.appspot.com",
  messagingSenderId: "633900197081",
  appId: "1:633900197081:web:0de4bed6e36f56ca1a8ac8",
  measurementId: "G-4PJWG1T4ZP",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

import { useEffect, useState } from "react";

import logo from "./img/logo.png";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import { connectWallet, mintNFT } from "./utils/interact.js";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

const drawerWidth = 240;
const genres = [
  {
    value: "Alternative",
    label: "Alternative",
  },
  {
    value: "Alternative / College Rock",
    label: "Alternative / College Rock",
  },
  {
    value: "Alternative / Goth Rock",
    label: "Alternative / Goth Rock",
  },
  {
    value: "Alternative / Grunge",
    label: "Alternative / Grunge",
  },
  {
    value: "Alternative / Indie Rock",
    label: "Alternative / Indie Rock",
  },
  {
    value: "Alternative / New Wave",
    label: "Alternative / New Wave",
  },
  {
    value: "Alternative / Punk",
    label: "Alternative / Punk",
  },
  {
    value: "Blues",
    label: "Blues",
  },
  {
    value: "Blues / Acoustic Blues",
    label: "Blues / Acoustic Blues",
  },
  {
    value: "Blues / Chicago Blues",
    label: "Blues / Chicago Blues",
  },
  {
    value: "Blues / Classic Blues",
    label: "Blues / Classic Blues",
  },
  {
    value: "Blues / Contemporary Blues",
    label: "Blues / Contemporary Blues",
  },
  {
    value: "Blues / Country Blues",
    label: "Blues / Country Blues",
  },
  {
    value: "Blues / Delta Blues",
    label: "Blues / Delta Blues",
  },
  {
    value: "Blues / Electric Blues",
    label: "Blues / Electric Blues",
  },
  {
    value: "Children's Music",
    label: "Children's Music",
  },
  {
    value: "Children's Music / Lullabies",
    label: "Children's Music / Lullabies",
  },
  {
    value: "Children's Music / Sing-Along",
    label: "Children's Music / Sing-Along",
  },
  {
    value: "Country",
    label: "Country",
  },
  {
    value: "Country / Alternative Country",
    label: "Country / Alternative Country",
  },
  {
    value: "Country / Americana",
    label: "Country / Americana",
  },
  {
    value: "Country / Bluegrass",
    label: "Country / Bluegrass",
  },
  {
    value: "Country / Contemporary Bluegrass",
    label: "Country / Contemporary Bluegrass",
  },
  {
    value: "Country / Contemporary Country",
    label: "Country / Contemporary Country",
  },
  {
    value: "Country / Country Gospel",
    label: "Country / Country Gospel",
  },
  {
    value: "Country / Honky Tonk",
    label: "Country / Honky Tonk",
  },
  {
    value: "Country / Outlaw Country",
    label: "Country / Outlaw Country",
  },
  {
    value: "Country / Traditional Bluegrass",
    label: "Country / Traditional Bluegrass",
  },
  {
    value: "Country / Traditional Country",
    label: "Country / Traditional Country",
  },
  {
    value: "Country / Urban Cowboy",
    label: "Country / Urban Cowboy",
  },
  {
    value: "Dance",
    label: "Dance",
  },
  {
    value: "Dance / Breakbeat",
    label: "Dance / Breakbeat",
  },
  {
    value: "Dance / Garage",
    label: "Dance / Garage",
  },
  {
    value: "Dance / Hardcore",
    label: "Dance / Hardcore",
  },
  {
    value: "Dance / House",
    label: "Dance / House",
  },
  {
    value: "Dance / Jungle/Drum’n’bass",
    label: "Dance / Jungle/Drum’n’bass",
  },
  {
    value: "Dance / Techno",
    label: "Dance / Techno",
  },
  {
    value: "Dance / Trance",
    label: "Dance / Trance",
  },
  {
    value: "Dance / Trap",
    label: "Dance / Trap",
  },
  {
    value: "Electronic",
    label: "Electronic",
  },
  {
    value: "Electronic / Ambient",
    label: "Electronic / Ambient",
  },
  {
    value: "Electronic / Downtempo",
    label: "Electronic / Downtempo",
  },
  {
    value: "Electronic / Dubstep",
    label: "Electronic / Dubstep",
  },
  {
    value: "Electronic / Electronica",
    label: "Electronic / Electronica",
  },
  {
    value: "Electronic / Future Bass",
    label: "Electronic / Future Bass",
  },
  {
    value: "Electronic / IDM/Experimental",
    label: "Electronic / IDM/Experimental",
  },
  {
    value: "Electronic / Industrial",
    label: "Electronic / Industrial",
  },
  {
    value: "Electronic / Minimalism",
    label: "Electronic / Minimalism",
  },
  {
    value: "Folk",
    label: "Folk",
  },
  {
    value: "Hip Hop/Rap",
    label: "Hip Hop/Rap",
  },
  {
    value: "Hip Hop/Rap / Alternative Rap",
    label: "Hip Hop/Rap / Alternative Rap",
  },
  {
    value: "Hip Hop/Rap / Dirty South",
    label: "Hip Hop/Rap / Dirty South",
  },
  {
    value: "Hip Hop/Rap / East Cost Rap",
    label: "Hip Hop/Rap / East Cost Rap",
  },
  {
    value: "Hip Hop/Rap / Gangsta Rap",
    label: "Hip Hop/Rap / Gangsta Rap",
  },
  {
    value: "Hip Hop/Rap / Hardcore Rap",
    label: "Hip Hop/Rap / Hardcore Rap",
  },
  {
    value: "Hip Hop/Rap / Hip-Hop",
    label: "Hip Hop/Rap / Hip-Hop",
  },
  {
    value: "Hip Hop/Rap / Latin Rap",
    label: "Hip Hop/Rap / Latin Rap",
  },
  {
    value: "Hip Hop/Rap / Old School Rap",
    label: "Hip Hop/Rap / Old School Rap",
  },
  {
    value: "Hip Hop/Rap / Rap",
    label: "Hip Hop/Rap / Rap",
  },
  {
    value: "Hip Hop/Rap / Underground Rap",
    label: "Hip Hop/Rap / Underground Rap",
  },
  {
    value: "Hip Hop/Rap / West Coast Rap",
    label: "Hip Hop/Rap / West Coast Rap",
  },
  {
    value: "Holiday",
    label: "Holiday",
  },
  {
    value: "Holiday / Chanukah",
    label: "Holiday / Chanukah",
  },
  {
    value: "Holiday / Christmas",
    label: "Holiday / Christmas",
  },
  {
    value: "Holiday / Christmas: Children’s",
    label: "Holiday / Christmas: Children’s",
  },
  {
    value: "Holiday / Christmas: Classic",
    label: "Holiday / Christmas: Classic",
  },
  {
    value: "Holiday / Christmas: Classical",
    label: "Holiday / Christmas: Classical",
  },
  {
    value: "Holiday / Christmas: Jazz",
    label: "Holiday / Christmas: Jazz",
  },
  {
    value: "Holiday / Christmas: Modern",
    label: "Holiday / Christmas: Modern",
  },
  {
    value: "Holiday / Christmas: Pop",
    label: "Holiday / Christmas: Pop",
  },
  {
    value: "Holiday / Christmas: R&amp;B",
    label: "Holiday / Christmas: R&amp;B",
  },
  {
    value: "Holiday / Christmas: Religious",
    label: "Holiday / Christmas: Religious",
  },
  {
    value: "Holiday / Christmas: Rock",
    label: "Holiday / Christmas: Rock",
  },
  {
    value: "Holiday / Easter",
    label: "Holiday / Easter",
  },
  {
    value: "Holiday / Halloween",
    label: "Holiday / Halloween",
  },
  {
    value: "Holiday / Thanksgiving",
    label: "Holiday / Thanksgiving",
  },
  {
    value: "Inspirational",
    label: "Inspirational",
  },
  {
    value: "Jazz",
    label: "Jazz",
  },
  {
    value: "Jazz / Avant-Garde Jazz",
    label: "Jazz / Avant-Garde Jazz",
  },
  {
    value: "Jazz / Big Band",
    label: "Jazz / Big Band",
  },
  {
    value: "Jazz / Contemporary Jazz",
    label: "Jazz / Contemporary Jazz",
  },
  {
    value: "Jazz / Cool",
    label: "Jazz / Cool",
  },
  {
    value: "Jazz / Crossover Jazz",
    label: "Jazz / Crossover Jazz",
  },
  {
    value: "Jazz / Dixieland",
    label: "Jazz / Dixieland",
  },
  {
    value: "Jazz / Easy Listening",
    label: "Jazz / Easy Listening",
  },
  {
    value: "Jazz / Fusion",
    label: "Jazz / Fusion",
  },
  {
    value: "Jazz / Hard Bop",
    label: "Jazz / Hard Bop",
  },
  {
    value: "Jazz / Latin Jazz",
    label: "Jazz / Latin Jazz",
  },
  {
    value: "Jazz / Mainstream Jazz",
    label: "Jazz / Mainstream Jazz",
  },
  {
    value: "Jazz / Ragtime",
    label: "Jazz / Ragtime",
  },
  {
    value: "Jazz / Smooth Jazz",
    label: "Jazz / Smooth Jazz",
  },
  {
    value: "Jazz / Trad Jazz",
    label: "Jazz / Trad Jazz",
  },
  {
    value: "Latin",
    label: "Latin",
  },
  {
    value: "Latin/ Alternativo &amp; Rock Latino",
    label: "Latin/ Alternativo &amp; Rock Latino",
  },
  {
    value: "Latin/ Baladas y Boleros",
    label: "Latin/ Baladas y Boleros",
  },
  {
    value: "Latin / Contemporary Latin",
    label: "Latin / Contemporary Latin",
  },
  {
    value: "Latin / Pop Latino",
    label: "Latin / Pop Latino",
  },
  {
    value: "Latin / Raíces",
    label: "Latin / Raíces",
  },
  {
    value: "Latin / Reggaeton y Hip-Hop",
    label: "Latin / Reggaeton y Hip-Hop",
  },
  {
    value: "Latin / Regional Mexicano",
    label: "Latin / Regional Mexicano",
  },
  {
    value: "Latin / Salsa y Tropical",
    label: "Latin / Salsa y Tropical",
  },
  {
    value: "New Age",
    label: "New Age",
  },
  {
    value: "New Age / Healing",
    label: "New Age / Healing",
  },
  {
    value: "New Age / Meditation",
    label: "New Age / Meditation",
  },
  {
    value: "New Age / Nature",
    label: "New Age / Nature",
  },
  {
    value: "New Age / Relaxation",
    label: "New Age / Relaxation",
  },
  {
    value: "New Age / Travel",
    label: "New Age / Travel",
  },
  {
    value: "Pop",
    label: "Pop",
  },
  {
    value: "Pop / Adult Contemporary",
    label: "Pop / Adult Contemporary",
  },
  {
    value: "Pop / Britpop",
    label: "Pop / Britpop",
  },
  {
    value: "Pop / Pop/Rock",
    label: "Pop / Pop/Rock",
  },
  {
    value: "Pop / Singer/Songwriter",
    label: "Pop / Singer/Songwriter",
  },
  {
    value: "Pop / Soft Rock",
    label: "Pop / Soft Rock",
  },
  {
    value: "Pop / Teen Pop",
    label: "Pop / Teen Pop",
  },
  {
    value: "R&amp;B/Soul",
    label: "R&amp;B/Soul",
  },
  {
    value: " R&amp;B/Soul / Contemporary R&amp;B",
    label: " R&amp;B/Soul / Contemporary R&amp;B",
  },
  {
    value: " R&amp;B/Soul / Disco",
    label: " R&amp;B/Soul / Disco",
  },
  {
    value: " R&amp;B/Soul / Doo Wop",
    label: " R&amp;B/Soul / Doo Wop",
  },
  {
    value: " R&amp;B/Soul / Funk",
    label: " R&amp;B/Soul / Funk",
  },
  {
    value: " R&amp;B/Soul / Motown",
    label: " R&amp;B/Soul / Motown",
  },
  {
    value: " R&amp;B/Soul / Neo-Soul",
    label: " R&amp;B/Soul / Neo-Soul",
  },
  {
    value: " R&amp;B/Soul / Soul",
    label: " R&amp;B/Soul / Soul",
  },
  {
    value: "Reggae",
    label: "Reggae",
  },
  {
    value: "Reggae / Dub",
    label: "Reggae / Dub",
  },
  {
    value: "Reggae / Roots Reggae",
    label: "Reggae / Roots Reggae",
  },
  {
    value: "Reggae / Ska",
    label: "Reggae / Ska",
  },
  {
    value: "Rock",
    label: "Rock",
  },
  {
    value: "Rock / Adult Alternative",
    label: "Rock / Adult Alternative",
  },
  {
    value: "Rock / American Trad Rock",
    label: "Rock / American Trad Rock",
  },
  {
    value: "Rock / Arena Rock",
    label: "Rock / Arena Rock",
  },
  {
    value: "Rock / Blues-Rock",
    label: "Rock / Blues-Rock",
  },
  {
    value: "Rock / British Invasion",
    label: "Rock / British Invasion",
  },
  {
    value: "Rock / Death Metal/Black Metal",
    label: "Rock / Death Metal/Black Metal",
  },
  {
    value: "Rock / Glam Rock",
    label: "Rock / Glam Rock",
  },
  {
    value: "Rock / Hair Metal",
    label: "Rock / Hair Metal",
  },
  {
    value: "Rock / Hard Rock",
    label: "Rock / Hard Rock",
  },
  {
    value: "Rock / Heavy Metal",
    label: "Rock / Heavy Metal",
  },
  {
    value: "Rock / Jam Bands",
    label: "Rock / Jam Bands",
  },
  {
    value: "Rock / Prog-Rock/Art Rock",
    label: "Rock / Prog-Rock/Art Rock",
  },
  {
    value: "Rock / Psychedelic",
    label: "Rock / Psychedelic",
  },
  {
    value: "Rock / Rockabilly",
    label: "Rock / Rockabilly",
  },
  {
    value: "Rock / Rock &amp; Roll",
    label: "Rock / Rock &amp; Roll",
  },
  {
    value: "Rock / Roots Rock",
    label: "Rock / Roots Rock",
  },
  {
    value: "Rock / Southern Rock",
    label: "Rock / Southern Rock",
  },
  {
    value: "Rock / Surf",
    label: "Rock / Surf",
  },
  {
    value: "Rock / Tex-Mex",
    label: "Rock / Tex-Mex",
  },
  {
    value: "Soundtrack",
    label: "Soundtrack",
  },
  {
    value: "Soundtrack / Foreign Cinema",
    label: "Soundtrack / Foreign Cinema",
  },
  {
    value: "Soundtrack / Musicals",
    label: "Soundtrack / Musicals",
  },
  {
    value: "Soundtrack / Original Score",
    label: "Soundtrack / Original Score",
  },
  {
    value: "Soundtrack / TV Soundtrack",
    label: "Soundtrack / TV Soundtrack",
  },
  {
    value: "Spoken Word",
    label: "Spoken Word",
  },
  {
    value: "Vocal",
    label: "Vocal",
  },
  {
    value: "Vocal / Standards",
    label: "Vocal / Standards",
  },
  {
    value: "Vocal / Traditional Pop",
    label: "Vocal / Traditional Pop",
  },
  {
    value: "Vocal / Vocal Jazz",
    label: "Vocal / Vocal Jazz",
  },
  {
    value: "Vocal / Vocal Pop",
    label: "Vocal / Vocal Pop",
  },
  {
    value: "World",
    label: "World",
  },
  {
    value: "World / Afrobeat",
    label: "World / Afrobeat",
  },
  {
    value: "World / Afro Pop",
    label: "World / Afro Pop",
  },
  {
    value: "World / Christian &amp; Gospel",
    label: "World / Christian &amp; Gospel",
  },
  {
    value: "World / Tango",
    label: "World / Tango",
  },
];

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    // display: "flex",
  },
  logo: {
    // flexGrow: "1",
    cursor: "pointer",
    fontSize: 22,
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 17,
    fontWeight: "900",
    marginLeft: theme.spacing(2),
    textDecoration: "none !important",
    "&:hover": {
      borderBottom: "1px solid white",
    },
  },
  appBar: {
    width: `calc(100% - 0px)`,
    // marginLeft: drawerWidth,
    backgroundColor: "#1212e3",
  },
  toolbarm: {
    justifyContent: "space-between",
  },
  root: {
    display: "flex",
  },
  size: {
    height: 30,
    width: 110,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageLinks: {
    marginLeft: "60%",
    //marginTop:100
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const Minter = (props) => {
  //State variables
  const [isConnected, setConnectedStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  //  const [url, setURL] = useState("");
  const [artwork, setArtwork] = useState("");
  const [waveform, setWaveform] = useState("");
  const [genre, setGenre] = useState("");
  const [artist, setArtist] = useState("");

  useEffect(async () => {
    //TODO: implement
  });

  const connectWalletPressed = async () => {
    //TODO: implement
    const walletResponse = await connectWallet();
    setConnectedStatus(walletResponse.connectedStatus);
    setStatus(walletResponse.status);
    if (isConnected) {
      setWallet(walletAddress);
    }
  };

  const onMintPressed = async () => {
    //TODO: implement
    const { status } = await mintNFT(artist, artwork, waveform, name, genre);
    setStatus(status);
  };

  //const  mintTestPress = async () => {
  //   console.log(name)
  //   console.log(genre)
  //   console.log(waveform)
  //  console.log(artwork)
  //   console.log(artist)
  // }

  useEffect(async () => {
    if (window.ethereum) {
      //if Metamask installed
      try {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        }); //get Metamask wallet
        if (accounts.length) {
          //if a Metamask account is connected
          setConnectedStatus(true);
          setWallet(accounts[0]);
        } else {
          setConnectedStatus(false);
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      } catch {
        setConnectedStatus(false);
        setStatus(
          "🦊 Connect to Metamask using the top right button. " + walletAddress
        );
      }
    }
  });

  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <CssBaseline />
        <Toolbar className={classes.toolbarm}>
          <Typography variant="h4" className={classes.logo}>
            Kash4Crypto
          </Typography>
          <div className={classes.navlinks}>
            <Link href="/" className={classes.link}>
              Mint Token
            </Link>
            <Link href="/UploadArtwork" className={classes.link}>
              Upload Artwork
            </Link>
            <Link href="/UploadAudio" className={classes.link}>
              Upload Audio
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={2}></Grid>

        <Grid item xs={8}>
          <Card style={{ height: 830 }}>
            <CardContent>
              <div className="Minter">
                <button id="walletButton" onClick={connectWalletPressed}>
                  {isConnected ? (
                    "👛 Connected: " +
                    String(walletAddress).substring(0, 6) +
                    "..." +
                    String(walletAddress).substring(38)
                  ) : (
                    <span>👛 Connect Wallet</span>
                  )}
                </button>

                <br></br>
                <h1 id="title">The Future Of Music Ownership!</h1>
                <p>
                  Simply add your asset's waveform, track name, artwork , artist
                  name and genre, then press "Mint."
                </p>
                <form>
                  <TextField
                    id="outlined-full-width"
                    label="Link To Artwork: ( Upload Your Artwork First )"
                    style={{ margin: 8 }}
                    placeholder="e.g. https://gateway.pinata.cloud/ipfs/<hash>"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setArtwork(event.target.value)}
                  />
                  <TextField
                    id="outlined-full-width"
                    label="Link To Waveform: ( Upload Your Audio File First)"
                    style={{ margin: 8 }}
                    placeholder="e.g. https://firebasestorage.googleapis.com/v0/b/distrotoken-hosting.appspot.com/o/<Stuff>"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setWaveform(event.target.value)}
                  />
                  <TextField
                    id="outlined-full-width"
                    label="Release Name:"
                    style={{ margin: 8 }}
                    placeholder="e.g. My first Audio NFT!"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setName(event.target.value)}
                  />
                  <TextField
                    id="outlined-full-width"
                    label="Artist Name:"
                    style={{ margin: 8 }}
                    placeholder="e.g. Michael Jackson!"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    onChange={(event) => setArtist(event.target.value)}
                  />
                  <TextField
                    id="genres"
                    name="genres"
                    style={{ margin: 8 }}
                    select
                    label="Genre:"
                    variant="outlined"
                    value={genre}
                    fullWidth
                    onChange={(event) => setGenre(event.target.value)}
                  >
                    {genres.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </form>
                <button id="mintButton" onClick={onMintPressed}>
                  Mint NFT
                </button>
                <p id="status">{status}</p>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </>
  );
};

export default Minter;

import { useEffect, useState } from "react";

import logo from "./img/logo.png";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Input,
  Button,
  Link,
  Typography,
  Toolbar,
  AppBar,
  CssBaseline,
} from "@material-ui/core";

import { connectWallet, mintNFT } from "./utils/interact.js";

const drawerWidth = 240;

const axios = require("axios");
const fs = require("fs");
const FormData = require("form-data");

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    // display: "flex",
  },
  logo: {
    // flexGrow: "1",
    cursor: "pointer",
    fontSize: 22,
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: 17,
    fontWeight: "900",
    marginLeft: theme.spacing(2),
    textDecoration: "none !important",
    "&:hover": {
      borderBottom: "1px solid white",
    },
  },
  appBar: {
    width: `calc(100% - 0px)`,
    // marginLeft: drawerWidth,
    backgroundColor: "#1212e3",
  },
  toolbarm: {
    justifyContent: "space-between",
  },
  root: {
    display: "flex",
  },
  size: {
    height: 30,
    width: 110,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageLinks: {
    marginLeft: "60%",
    //marginTop:100
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

const UploadArtwork = (props) => {
  //State variables
  const [isConnected, setConnectedStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  //  const [url, setURL] = useState("");
  const [artwork, setArtwork] = useState("");
  const [waveform, setWaveform] = useState("");
  const [genre, setGenre] = useState("");
  const [artist, setArtist] = useState("");

  const [picture, setPicture] = useState(null);

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const [IPFSHash, setIPFS] = useState("");

  const [image, setImage] = useState({ preview: "", raw: "" });

  const types = ["image/png", "image/jpeg", "image/jpg"];

  let data = new FormData();

  const handleChange = (e) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });

    let selectedFile = e.target.files[0];
    //data.append('file',selectedFile)
    data.set("file", selectedFile);
    //console.log(data.get('file'))
    if (selectedFile) {
      if (types.includes(selectedFile.type)) {
        setError(null);
        setFile(selectedFile);
      } else {
        setFile(null);
        setError("Please select an image file (png or jpg)");
      }
    }
    handleFinalChange(data);
  };

  const handleFinalChange = (data) => {
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    axios
      .post(url, data, {
        maxBodyLength: "Infinity",
        headers: {
          pinata_api_key: "fb1a61550b00fa323825",
          pinata_secret_api_key:
            "85548bccfd501af0c3ed406ebb89c8c7e1ed0ff60551708169243e199e2f729f",
        },
      })
      .then(function (response) {
        //handle your response here
        console.log(response);
        setIPFS(response.data.IpfsHash);
      })
      .catch(function (error) {
        //handle error here
        console.log(error);
      });
  };

  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

  const handleUpload = async (e) => {
    e.preventDefault();
  };

  let myname = "Kiri";

  const classes = useStyles();

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <CssBaseline />
        <Toolbar className={classes.toolbarm}>
          <Typography variant="h4" className={classes.logo}>
            Kash4Crypto
          </Typography>
          <div className={classes.navlinks}>
            <Link href="/" className={classes.link}>
              Mint Token
            </Link>
            <Link href="/UploadArtwork" className={classes.link}>
              Upload Artwork
            </Link>
            <Link href="/UploadAudio" className={classes.link}>
              Upload Audio
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        <Grid item xs={6} style={{ paddingLeft: 20, textAlign: "center" }}>
          <h1 id="uploadTitle">
            Upload your artwork here in order to receive your URL for minting.
          </h1>
          <form>
            <label>
              <input type="file" onChange={handleChange} />
            </label>
          </form>
          <h3>
            {" "}
            Your url is :{" "}
            <h3 id="urlTitle">
              {" "}
              https://gateway.pinata.cloud/ipfs/{IPFSHash}{" "}
            </h3>{" "}
          </h3>
        </Grid>
        <Grid item xs={6}>
          <h1 id="uploadTitle">Steps to Upload your artwork.</h1>
          <h3> Step One : Select Your File </h3>
          <h3>
            {" "}
            Step Two : After You Have Selected Your File , The URL Below Will
            Update.{" "}
          </h3>
          <h3>
            {" "}
            Step Three : Make A Note Of This URL Because You Will Need It When
            Minting.{" "}
          </h3>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadArtwork;
